import {useState, useRef} from "react";
import Logo from "../../elements/logo/Logo";
import { FiMenu } from "react-icons/fi";
import {FiTwitter, FiInstagram} from "react-icons/fi";
import { FaDiscord } from 'react-icons/fa';
import MobileMenu from './MobileMenu';
import {Link} from "react-router-dom";
import useStickyHeader from "./useStickyHeader";



const HeaderTwo = ({btnStyle, HeaderSTyle}) => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }


    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader( 50 );
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`
    const { clientHeight } = ref;
    
    const checkChange = (value) => {
      setCheck(value);
    };


    return (
        <>
            <header ref={ref} className={`rn-header header-default header-left-align ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-6 col-4 position-static">
                            <div className="header-left d-flex">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/Logo_GoodFellazHIGHCompleteWHITE.png`}
                                    image2={`${process.env.PUBLIC_URL}/F&Qimages/logo/Logo_GoodFellazHIGHCompleteWHITE.png`}
                                />
                                <nav className="mainmenu-nav d-none d-lg-block">
                                     <ul className="mainmenu">
                                        <li><a href="#home">HOME</a></li>
                                        <li><a href="#about">ABOUT</a></li>
                                        <li><a href="#roadmap">ROADMAP</a></li>
                                        <li><a href="#utility">UTILITY</a></li>
                                        <li><a href="#team">TEAM</a></li>
                                        <li><a href="#faq">FAQ</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-8">
                            <div className="header-right mobile-responsive" style={{display:'flex',flexDirection:'row'}}>
                               
                                <div className="header-right text-center">
                                    <ul className="social-icon social-default with-gradient color-lessdark">
                                        <li></li>
                                        <li><a href="https://discord.gg/goodfellaz"><i className="icon"><FaDiscord size={22}/></i></a></li>
                                        <li><a href="https://twitter.com/GoodfellazNFT"><i className="icon"><FiTwitter size={22}/></i></a></li>
                                        <li><a href="https://www.instagram.com/goodfellaznft/"><i className="icon"><FiInstagram size={22}/></i></a></li>
                                        
                                        {/* <li><Link to="linkdin.com"><FiLinkedin /></Link></li> */}
                                    </ul>
                                </div>
                                <div className="header-btn">
                                    <a className={`btn-default ${btnStyle}`} target="_blank" href="#">Mint</a>
                                </div>
                                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div className="hamberger">
                                        <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler}  />
        </>
    )
}
export default HeaderTwo;