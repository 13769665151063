import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {GiConsoleController,GiEasel, GiTargetPrize, GiWatch} from "react-icons/gi";
import {GoGistSecret} from "react-icons/go";
import {AiOutlineLineChart} from "react-icons/ai";
import {IoIosRocket} from "react-icons/io";
import {FaFunnelDollar, FaRegHandshake } from "react-icons/fa";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <GiConsoleController />,
        title: 'Play to earn (P2E) Game',
        // description: 'Play to earn (P2E) Game – we are pushing the boundaries on our game build-up to make it a place where not only Goodfellaz, but NFT holders from different communities will want to be part of it. '
    },
    {
        icon: <GoGistSecret  />,
        title: 'Wizeguyz Collection Drop',
        // description: 'Wiseguy Collection: Whitelist access to 1/1 NFTs from a variety of well-known Artists.'
    },
    {
        icon: <FaFunnelDollar />,
        title: 'Passive Income for Life',
        // description: ' FREE Mint to future Goodfellaz NFT sets - Goodfellaz NFT holders will get 1 free mint on 2 future sets that are in the works. The main set is launching by the end of the year.'
    }, 
    {
        icon: <GiEasel />,
        title: 'Artist Collections & 1/1 NFTs',
        // description: 'Purchase Land in NFT World & build an events arena for Goodfellaz community member'
    },
    {
        icon: <GiTargetPrize />,
        title: 'Prizes, raffles, games',
        // description: 'Free drops and Exclusive whitelists from partnerships with New & Experienced artists '
    },
    {
        icon: < GiWatch />,
        title: 'Tourbillon Watch Collection',
        // description: ' FREE Mint to future Goodfellaz NFT sets - Goodfellaz NFT holders will get 1 free mint on 2 future sets that are in the works. The main set is launching by the end of the year.'
    }, 

    {
        icon: <AiOutlineLineChart  />,
        title: 'Lifetime Access to NFT Scoring Website',
        // description: 'NFT Collection Statistics website under construction - free to our community - nftcollectionstats.io'
    },
    {
        icon: <FaRegHandshake />,
        title: 'Junior Partner',
        // description: ' FREE Mint to future Goodfellaz NFT sets - Goodfellaz NFT holders will get 1 free mint on 2 future sets that are in the works. The main set is launching by the end of the year.'
    }, 
    {
        icon: <IoIosRocket />,
        title: 'Staking, Renting, $$Coin and More Coming Soon!!',
        // description: ' FREE Mint to future Goodfellaz NFT sets - Goodfellaz NFT holders will get 1 free mint on 2 future sets that are in the works. The main set is launching by the end of the year.'
    },  
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;