import React , {useState, useEffect,useRef}from 'react';
import Typed from 'react-typed';
// import {Animated} from "react-animated-css";
import { CSSTransition } from 'react-transition-group';
import SmoothScroll from '../../components/smoothscroll/SmoothScroll';
import '../../assets/scss/elements/about.scss'
import { Toast } from 'react-bootstrap';
import ScrollAnimation from "react-animate-on-scroll";

const AboutOne = () => {
    const [wdth, setWdth] = React.useState(0);
    React.useEffect(() => {
        setWdth(window.innerWidth)
  
    }, [])
    const [inProp, setInProp] = useState(false);
    const [in2Prop, setIn2Prop] = useState(false);
    const [fImageWidth, setFImageWidth] = useState('500px')
    const [fImageWidthNr, setFImageWidthNr] = useState(500)
    const refFirstImage = useRef({});

    const [SImageWidth, setSImageWidth] = useState('500px')
    const [SImageWidthNr, setSImageWidthNr] = useState(500)
    const refSecondImage = useRef({});

    const[lastPosition,setLastPosition]=  useState(0)
    const[lastPosition2,setLastPosition2]=  useState(0)

    const [scrollTop, setScrollTop] = useState(0);
    const [midpoint, setMidpoint] = useState(false);
    const [scrollCalc, setScrollCalc] = useState(0);
    const refContainer = useRef({});

    useEffect(() => {
        function onScroll() {
            let currentPosition = window.pageYOffset;
            //console.log(currentPosition);
            if(currentPosition == 0)
            {
                setInProp(false);
                setIn2Prop(false);
            }

            var fImage = refFirstImage.current.getBoundingClientRect();
            if(currentPosition == 0)
            {
                setLastPosition(0)
                setLastPosition2(0)

                setFImageWidthNr(500)
                setFImageWidth(500+'px')

                setSImageWidthNr(500)
                setSImageWidth(500+'px')
            }
            if(currentPosition - 400 > 0)
            {
                if(lastPosition < currentPosition)
                resizeFirstImage(currentPosition, 1)
                else
                resizeFirstImage(currentPosition, 0)
               
            }   
            if(currentPosition -800>0)
            {
                if(lastPosition2 < currentPosition)
                resizeSecondImage(currentPosition,1)
                else
                resizeSecondImage(currentPosition,0)
            }

            if (!inProp && currentPosition - 400 > 0) {
                setInProp(true);
            }
            if (!in2Prop && currentPosition - 800 > 0) {
                setIn2Prop(true);
            }

            let tt = refContainer.current.getBoundingClientRect();

            if (tt.y < window.innerHeight / 2) {
                if (!midpoint) {
                    setMidpoint(true);
                    setScrollCalc(currentPosition);
                }

                setScrollTop(((currentPosition - scrollCalc) / (tt.height)) * 100);
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

function resizeFirstImage(position, direction)
{
    if(fImageWidthNr> 400 &&  fImageWidthNr<600)
    {
    
    if(position-400>0 && position<1500)
    {
        if(direction == 1)
        {
        var toAdd = fImageWidthNr + 0.0015*fImageWidthNr;

        setFImageWidthNr(toAdd)
        setFImageWidth(toAdd+'px')
        console.log(fImageWidth)
        }
        else
        {
            var toAdd = fImageWidthNr - 0.0015*fImageWidthNr;

        setFImageWidthNr(toAdd)
        setFImageWidth(toAdd+'px')
        console.log(fImageWidth)
        }

        setLastPosition(position)
    }
    if(position-1000>0 && position<1400)
    {
        // if(direction == 1)
        // {
        // var toAdd = fImageWidthNr - 0.002*fImageWidthNr;
    
        // setFImageWidthNr(toAdd)
        // setFImageWidth(toAdd+'px')

        // } 
        // else
        // {
        //     var toAdd = fImageWidthNr + 0.002*fImageWidthNr;
    
        // setFImageWidthNr(toAdd)
        // setFImageWidth(toAdd+'px')
       
        // }
        // setLastPosition(position)
    }
}

    if(fImageWidthNr< 400 || fImageWidthNr>600)
    {
        if(fImageWidthNr< 400)
        {
           setFImageWidthNr(401)
        setFImageWidth(401+'px') 
        }
        if(fImageWidthNr>600)
        {
            setFImageWidthNr(599)
            setFImageWidth(599+'px')  
        }
       // setFImageWidthNr(500)
       // setFImageWidth(500+'px')
    }
    
}
function resizeSecondImage(position,direction)
{
    if(SImageWidthNr> 400 &&  SImageWidthNr<600)
    {
    if(position-1200>0 && position<2100)
    {
        if(direction == 1)
        {
        var toAdd =SImageWidthNr + 0.0015*SImageWidthNr;
        setSImageWidthNr(toAdd)
        setSImageWidth(toAdd+'px')
        }
        else
        {
            var toAdd =SImageWidthNr - 0.0015*SImageWidthNr;
            setSImageWidthNr(toAdd)
            setSImageWidth(toAdd+'px')
        }
    }
    if(position-1800>0 && position<2200)
    {
        // if(direction == 1)
        // {
        // var toAdd = SImageWidthNr - 0.002*SImageWidthNr;
        // setSImageWidthNr(toAdd)
        // setSImageWidth(toAdd+'px')
        // }
        // else
        // {
        //     var toAdd = SImageWidthNr + 0.002*SImageWidthNr;
        // setSImageWidthNr(toAdd)
        // setSImageWidth(toAdd+'px')
        // }
    }
    setLastPosition2(position)
}
    if(SImageWidthNr< 400 ||  SImageWidthNr>600)
    {
        if(SImageWidthNr< 400)
        {
           setSImageWidthNr(401)
        setSImageWidth(401+'px') 
        }
        if(SImageWidthNr>600)
        {
            setSImageWidthNr(599)
            setSImageWidth(599+'px')  
        }
       // setSImageWidthNr(500)
      //  setSImageWidth(500+'px')
    }
}



    return (

        <div className="rwt-about-area rn-section-gap" id="about" ref={refContainer}>
            <div className="container">
                <div className="row row--30 align-items-center" style={{marginBottom:'80px'}}>
                    <div className="col-lg-6" style={{display : wdth>768 ? '' : 'none'}}>
                        <div className="thumbnail">
                            <img ref={refFirstImage}  src="./images/nft_project_images/JacketSilverandCigar_0.png" alt="About Images" style={{width: fImageWidth,borderRadius:'6%', maxWidth:'200%'}} />
                            {/* <img className="w-100" src="./images/about/about-1.png" alt="About Images" /> */}
                        </div>
                    </div>
                  
                    <div className="col-lg-6 mt_md--60 mt_sm--40">
                        <div className="content">
                        {/* <CSSTransition
                                 in={inProp}
                                 timeout={300}
                                 classNames="enterTxt"
                                
                                 > */}
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut">
                            <div className="section-title">
                            {/* <div className="section-title" style={{ textAlign: 'justify'}}> */}
                                {/* <h2 className="title">About Us <br /> {" "} */}
                                    {/* <Typed className="theme-gradient"
                                        strings={[
                                            "Awsome.",
                                            "Great.",
                                            "Winning.",
                                        ]}style={{position:'relative', top:'200px'}}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    /> */}
                                {/* </h2> */}
                           
                                {/* <Animated animationIn="slideInUp" animationInDelay={500}  animateOnMount={true}  isVisible={true}> */}

                                <h2 className="text-edit" style={{fontweight: '700'}}>ABOUT</h2>

                                <p className="text-edit" >The Goodfellaz have arrived! Spawned from the 90s we are ready to open a can of whoop ass in the Metaverse! Looking to own a 3D NFT but having a hard time finding one you can relate to? Look no further! Goodfellaz are unique, professionally designed, and come with exciting built in utility, including royalties for life! Owning a Goodfella grants you access to a community of artists and entrepreneurs in a Metaverse that is ever expanding. Check out our roadmap to learn more and join our discord to be part of the action!</p>
                                
                                {/* <p className="text-edit">Owning a Goodfella grants you access to a community of artists and entrepreneurs. Some of the benefits are access to the Goodfellaz P2E game,
                                 virtual art galleries, an NFT Scoring site, community prizes, 1 of 1 NFTs, unique artist collections, and more.</p>


                                <p className="text-edit" >The Goodfellaz Universe is ever expanding. Check out our roadmap to learn more and <a href="https://discord.gg/goodfellaz">join our discord </a>to be part of the action!</p> */}
                                {/* <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div> */}
                                 {/* </Animated> */}
                                 
                            </div>
                            {/* </CSSTransition> */}
                            </ScrollAnimation>
                           
                        </div>
                    </div>

                </div>

                                
                <div className="row row--30 align-items-center">
                  

                    <div className="col-lg-6 mt_md--60 mt_sm--40">
                        <div className="content">
                        {/* <CSSTransition
                                 in={in2Prop}
                                 timeout={300}
                                 classNames="enterTxt2"
                                 > */}
                                 <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut">
                            <div className="section-title">
                                {/* <h2 className="title">About Us <br /> {" "} */}
                                    {/* <Typed className="theme-gradient"
                                        strings={[
                                            "Awsome.",
                                            "Great.",
                                            "Winning.",
                                        ]} style={{position:'relative', top:'200px'}}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    /> */}
                                {/* </h2> */}
                               
                                    <h2 className="text-edit">THE VISION</h2>
                                <p className="text-edit">Our vision is to build a strong community where Goodfellaz can network, learn, and benefit from one another while our deeply creative authentic artists build NFT collections that offer utility in perpetuity. We believe in the value of amazing design and aesthetics, and strive to create an experience for our community that will be rival to any other projects out there. Driven by our appreciation for artistic creativity and the entrepreneurial spirit we look forward to working with our community to navigate the web 3.0 landscape over the coming years! </p>

                                 {/* <p className="text-edit">
                                 We believe in the value of amazing design and aesthetics and strive to create an experience for our community members that will be rival to any other projects out there. Goodfellaz will grant access to exclusive experiences, early access to products, airdrops, physical items, exclusive invites to events, and much more.
                                 </p>

                                 <p className="text-edit">
                                 Driven by our appreciation for artistic creativity and the entrepreneurial spirit we look forward to working with our community to navigate the web 3.0 landscape over the coming years!
                                 </p>                      */}
                               
                            </div>
                             {/* </CSSTransition> */}
                             </ScrollAnimation>
                        </div>
                    </div>


                    <div className="col-lg-6" style={{display : wdth>768 ? '' : 'none'}}>
                        <div className="thumbnail">
                            <img ref={refSecondImage} src="./images/nft_project_images/JacketRedNOBAND_0.png" alt="About Images" style={{width: SImageWidth,borderRadius:'6%',maxWidth:'200%'}} />
                            {/* <img className="w-100" src="./images/about/about-1.png" alt="About Images" /> */}
                        </div>
                    </div>


                </div>
            </div>   
        </div>
  
    )
}

export default AboutOne
