import React, { useState, useReducer, useRef, useEffect } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

import {BrowserRouter, Route, Switch} from "react-router-dom";
import SEO from "./common/SEO";
import HeaderTwo from './common/header/HeaderTwo';
import FooterThree from './common/footer/FooterThree';
import ServiceOne from './elements/service/ServiceOne';
import SectionTitle from "./elements/sectionTitle/SectionTitle";
import AboutOne from './elements/about/AboutOne';
import Separator from "./elements/separator/Separator";
import TeamTwo from './elements/team/TeamTwo';
import AboutThree from './elements/about/AboutThree';
import 'react-vertical-timeline-component/style.min.css';
import { Timeline } from './components/timelines/timeline';
import Training from './components/TrainingSection/training';
import ScrollAnimation from "react-animate-on-scroll";
// import BlogList from "./components/blog/itemProp/BlogList";
// Import Css Here 
import './assets/scss/style.scss';

import BlogClassicData from './data/blog/BlogList.json';

var BlogListData = BlogClassicData.slice(0, 3);



const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/embed/5F5O6SbdCDE',
        ],
    },
    {
        id: "02",
        image: "./images/bg/bg-image-5.jpg",
        popupLink: [
            'https://www.youtube.com/embed/sbTVZMJ9Z2I',]
    }
]




const App = () => {


    return (
        <BrowserRouter>
                <SEO title="Goodfellaz" />
                <main className="page-wrapper" id="home">
                    <HeaderTwo btnStyle="round" HeaderSTyle="header-transparent" />
                    {/* <SmoothScroll> */}


                        <div className="single-slide">
                            <div className="height-950 bg_image" data-black-overlay="5" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/nft_project_images/test-banner.jpg)`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-12">
                                            <div className="inner text-center">
                                                <div className="button-group mt--30">
                                                    {/* <Link className="btn-default" to="#">PURCHASE NOW</Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    <Separator />

                    {/* Start About Area  */}
                    <AboutOne />
                    {/* End About Area  */}



                    <Separator />

                    {/* End Elements Area  */}

                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div style={{ textAlign: 'center', color: '#ffffff', fontSize: '44px', fontFamily: 'inter, san serif', fontWeight: '700', marginBottom: '25px' }} id="roadmap">Road Map</div>
                    </ScrollAnimation>


                    <Timeline />

                    {/* End Timeline Area  */}
                    {/* <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div style={{ textAlign: 'center', color: '#ffffff', fontSize: '44px', fontFamily: 'inter, san serif', fontWeight: '700', marginBottom: '25px' }}>How to mint</div>
                        <Training PopupData={PopupData} />
                    </ScrollAnimation> */}
                    {/* End Video Area  */}
                    <Separator />
                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gapBottom pt--100" id="utility">
                    <div style={{ textAlign: 'center', color: '#ffffff', fontSize: '44px', fontFamily: 'inter, san serif', fontWeight: '700', marginBottom: '25px' }} id="roadmap">Utility</div>
                        <div className="container">
                            <ServiceOne
                                serviceStyle="service__style--1 icon-circle-style"
                                textAlign="text-center"
                            />
                        </div>
                    </div>
                    {/* End Service Area  */}


                    <Separator />
                    {/* Start Team Area  */}
                    <div className="rwt-team-area rn-section-gap" id="team">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        // subtitle="Our Experts"
                                        title="The Goodfellaz Team"
                                        // description="We provide the best NFTS on the market <br /> lorem ipsum."
                                    />
                                </div>
                            </div>
                            <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two" />
                        </div>
                    </div>
                    {/* End Team Area  */}
                    <Separator />

                    {/* Start FAQ Area   */}
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <AboutThree />
                    </ScrollAnimation>
                    {/* Start FAQ Area  */}

                    <Separator />
                   
                    {/* End Elements Area  */}

                    <FooterThree />

                </main>

        </BrowserRouter>

    )
}


export default App
