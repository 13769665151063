import React, { useState, userRef, useRef } from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { BiRightArrow, BiLeftArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { CSSTransition } from 'react-transition-group';

const teamData = [
    {
        image: '42',
        name: 'Frankie',
        designation: 'Lead Code Wizard and Emperor of Bit-Land',
        location: 'Goodfellaz World',
        // description: 'Yes, I am a product designer. I have a passion for product design.',
        // socialNetwork: [
        //     {
        //         icon: <FiFacebook />,
        //         url: 'https://nlu.lightning.force.com/lightning/page/home'
        //     },
        //     {
        //         icon: <FiTwitter />,
        //         url: '#'
        //     },
        //     {
        //         icon: <FiInstagram />,
        //         url: '#'
        //     },
        // ]

    },
    {
        image: '190',
        name: 'Uncle-G',
        designation: 'Brains of Strategy and Dream Alchemist',
        location: 'Goodfellaz World',
    },
    {
        image: '179',
        name: 'Chrissy',
        designation: 'Head of Buzz and Conversation Architect',
        location: 'Goodfellaz World',
    },
    {
        image: '000_TONY_AVATAR_0',
        name: 'Tony',
        designation: 'Creative Director & 3D Visual Artist',
        location: 'Goodfellaz World',
    },
    {
        image: '98',
        name: 'Rid',
        designation: 'Design Rockstar and Crayon Evangelist',
        location: 'Goodfellaz World',

    },
    {
        image: '157',
        name: 'Vinny',
        designation: 'Brand Partnerships & Collaborations, and BD Ninja',
        location: 'Goodfellaz World',
    },
]

const TeamTwo = ({ column, teamStyle }) => {

    const [rightArrow, setRightArrow] = useState(true);
    const teamDiv = useRef(null);

    let scrollAmount = 0;

    const onScroll = () => {
        if(teamDiv.current.scrollLeft === 0){
            setRightArrow(true);
        }
        else{
            setRightArrow(false);
        }
      }

    const scroll = (order) => {
        if (order === 0) {
            sideScroll(teamDiv.current, 'right', 25, window.innerWidth - 30, 10);
            //teamDiv.current.scrollLeft += window.innerWidth - 30;
        }
        else {
            sideScroll(teamDiv.current, 'left', 25, window.innerWidth - 30, 10);
            //teamDiv.current.scrollLeft -= window.innerWidth - 30;
        }
    };

    function sideScroll(element, direction, speed, distance, step) {
        scrollAmount = 0;
        var slideTimer = setInterval(function () {
            if (direction == 'left') {
                if(scrollAmount + step > distance){
                    element.scrollLeft -= (distance - scrollAmount);
                }
                else{
                    element.scrollLeft -= step;
                }                
            } else {
                if (scrollAmount + step > distance) {
                    element.scrollLeft += (distance - scrollAmount);
                }
                else {
                    element.scrollLeft += step;
                }
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    return (
        <div className="teamContainerMobile" id='teamMembers' ref={teamDiv} onScroll={onScroll}>
            {teamData.map((data, index) => (
                <div className='fullWidthMobile' key={index}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {index === 0 || !rightArrow ?
                                <div className='arrowIcon' style={{ opacity: '0' }}><BiLeftArrow /></div>
                                :

                                <div className='arrowIcon' onClick={() => scroll(1)}><BiLeftArrow /></div>}


                            <div className={`rn-team ${teamStyle}`} style={{ flexGrow: '5' }}>
                                <div className="inner">
                                    <figure className="thumbnail">
                                        <img src={`./images/team/${data.image}.png`} alt="Corporate React Template" />
                                    </figure>
                                    <figcaption className="content">
                                        <h2 className="title">{data.name}</h2>
                                        <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                        <span className="team-form">
                                            <img src="./images/team/location.svg" alt="Corporate React Template" />
                                            <span className="location">{data.location}</span>
                                        </span>
                                        <p className="description">{data.description}</p>

                                        {/* <ul className="social-icon social-default icon-naked mt--20" >
                                            {data.socialNetwork.map((social, index) =>
                                                <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                            )}
                                        </ul> */}
                                    </figcaption>
                                </div>
                            </div>
                            {index === teamData.length - 1 || !rightArrow ?
                                <div className='arrowIcon' style={{ opacity: '0' }}><BiRightArrow /></div>
                                :

                                <div className='arrowIcon' onClick={() => scroll(0)}><BiRightArrow /></div>}
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TeamTwo;
