import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    What is a NFT?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>NFT stands for “Non-fungible token” and is a cool way of saying it’s a truly unique digital item that YOU can buy, own, and trade. Goodfellaz NFTs are written on the ERC721 contract and will allow you to verify ownership across multiple platforms.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    What is a (P2E) Play to Earn Game?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>A P2E game is just that, a game that can be played in a virtual world where gamers play for crypto token rewards.  Within our P2E game, token holders will have access to in-game prizes, raffles and exclusive whitelist spots. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                How can I be a Goodfella?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Get the Metamask Chrome or Firefox extension. Load it with ETH through services that allow you to change your money to ETH like Coinbase, Binance or Kraken. Then, click the button "MINT" on the top banner of the page to connect your wallet and approve the transaction on Metamask. That’s it!</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                   How do I get on the Whitelist?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>
                    You can claim a whitelist by following the instructions in <a href ="https://discord.gg/goodfellaz">Discord</a> or by participating in one of our <a href ="https://twitter.com/GoodfellazNFT">Twitter</a> give-a-ways. 
We will also be rewarding Whitelist spots daily leading up to the MINT to people who complete the most <a href ="https://discord.gg/goodfellaz">Discord</a> referrals, and who act as a brand ambassadors for Goodfellaz in <a href ="https://twitter.com/GoodfellazNFT">Twitter</a>. Join our <a href ="https://discord.gg/goodfellaz">Discord</a> and <a href ="https://twitter.com/GoodfellazNFT">Twitter</a> to learn more!

                     </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                   Why the Goodfellaz?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body> 
                    When you become a Goodfella, you are not only buying a collectible piece of art, but, you are also gaining access to a community where benefits and utilities will increase with time.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                When will the Goodfellaz Mint?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body> 
                    Minting date will be announced soon on our <a href ="https://discord.gg/goodfellaz">Discord</a>. We will be holding a presale for our Whitelist members 1 day prior to the public sale. 
                    Stay tuned into our <a href ="https://discord.gg/goodfellaz">Discord</a> and <a href ="https://twitter.com/GoodfellazNFT">Twitter</a> 
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

