import React, { Component, useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import ScrollReveal from 'scrollreveal'
// import '../../assets/timeline/timeline.css';
import '../../assets/timeline/timeline2.scss';
import styled from 'styled-components';
import {IoIosRocket} from "react-icons/io";
import { CSSTransition } from 'react-transition-group';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ScrollAnimation from "react-animate-on-scroll";

const StyledTimeline = styled.section`
transition: all;
position: relative;
&::before {
    transition: all;
		content: '';
		background: ${props => "linear-gradient(180deg, #ffb613 " + props.scroll + "%, #c5cae9 0%)"};
		width: 8px;
		height: 100%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
`;

export const Timeline = () => {
    const [scrollTop, setScrollTop] = useState(0);
    const halfWindow = window.innerHeight / 2;
    const refContainer = useRef({});

    const refFirstImage = useRef({});
    const [firstImage, setFirstImage] = useState(false);
    const refSecondImage = useRef({});
    const [secondImage, setSecondImage] = useState(false);
    const refThirdimage = useRef({});
    const [thirdImage, setThirdImage] = useState(false);
    const refFourthImage = useRef({});
    const [fourthImage, setFourthImage] = useState(false);

    const refFifthImage = useRef({});
    const [fifthImage, setFifthImage] = useState(false);

    const refSixthImage = useRef({});
    const [sixthImage, setSixthImage] = useState(false);

    useEffect(() => {
        function onScroll() {
            let currentPosition = window.pageYOffset;

            if(refContainer.current.getBoundingClientRect().y < halfWindow){
                let scrolledElement = Math.abs(halfWindow - refContainer.current.getBoundingClientRect().y);
                setScrollTop(((scrolledElement/refContainer.current.getBoundingClientRect().height))*100);
            }
            else{
                setScrollTop(0);
            }

            if(refFirstImage.current.getBoundingClientRect().y <= halfWindow){
                setFirstImage(true);
            }
            else{
                setFirstImage(false);
            }

            if(refSecondImage.current.getBoundingClientRect().y <= halfWindow){
                setSecondImage(true);
            }
            else{
                setSecondImage(false);
            }

            if(refThirdimage.current.getBoundingClientRect().y <= halfWindow){
                setThirdImage(true);
            }
            else{
                setThirdImage(false);
            }

            if(refFourthImage.current.getBoundingClientRect().y <= halfWindow){
                setFourthImage(true);
            }
            else{
                setFourthImage(false);
            }

            if(refFifthImage.current.getBoundingClientRect().y <= halfWindow){
                setFifthImage(true);
            }
            else{
                setFifthImage(false);
            }


            if(refSixthImage.current.getBoundingClientRect().y <= halfWindow){
                setSixthImage(true);
            }
            else{
                setSixthImage(false);
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    const [wdth, setWdth] = React.useState(0);
    React.useEffect(() => {
        setWdth(window.innerWidth)
    }, [])
    $(function () {
        window.sr = ScrollReveal();
    });

    return (
        <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
            <StyledTimeline className="timeline2"  scroll={scrollTop}>
                <div className="container" ref={refContainer} id={'section1'}>
                    <div className="timeline-item">
                        <div className={!firstImage ? 'timeline-img' : 'timeline-img activeBullet'} ref={refFirstImage}></div>
                        <div className="timeline-content">
                            <div className='timelineContainer'>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn={wdth>768 ? "slideInLeft" : "slideInRight"}
                                        animateOut="slideInRight"
                                        delay={200}
                                        duration={2.0}
                                        animateOnce={true}>
                                        <div className='content2 timelinContent timlineSylingRight'>
                                            <h2 style={{ color: '#008EFF', fontSize: '1.5em' }}> Minting and Passive Income for Life</h2>
                                            <p>
                                            Mint your 3D Goodfellaz NFT. 50% of all Royalties will go to the community and distributed as follows: 25% will go to the community fund and 25 Goodfellaz NFTs will carry a royalty trait with each NFT receiving <b><u>1% royalty for life</u></b>. Enjoy your collectable one of one art piece, and get ready to move to the metaverse!
                                            </p>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn="slideInRight"
                                        animateOut="slideInLeft"
                                        delay={200}
                                        duration={2.0}
                                        animateOnce={true}>
                                        <div className='content2 timelinContent imageItem'>
                                            <span className='timelineImageRight'><img alt='no image' src="./images/nft_project_images/goodfella4.png"
                                                style={{}} /></span>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className={!secondImage ? 'timeline-img' : 'timeline-img activeBullet'} ref={refSecondImage}></div>
                        <div className="timeline-content">
                            <div className='timelineContainer'>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn={wdth>768 ? "slideInLeft" : "slideInRight"}
                                        animateOut="slideInRight"
                                        delay={300}
                                        duration={2.0}
                                        animateOnce={true}>
                                            <div className='content2 timelinContent imageItem'>
                                            <span className='timelineImageLeft'><img alt='no image' src="./images/nft_project_images/goodfella2.png"
                                                style={{}} /></span>
                                        </div>                                        
                                    </ScrollAnimation>
                                </div>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn="slideInRight"
                                        animateOut="slideInLeft"
                                        delay={300}
                                        duration={2.0}
                                        animateOnce={true}>
                                            <div className='content2 timelinContent timeLineMarginLeft timlineSylingLeft'>
                                            <h2 style={{ color: '#008EFF', fontSize: '1.5em' }}>(P2E) Play To Earn Game</h2>
                                            <p>
                                            Use your Goodfellaz NFT as an access-pass to the Goodfellaz P2E game. We are working with an experienced gaming development company to build the Goodfellaz game.
                                             P2E with PVE challenges and PVP battles, play to enter contest and earn tokens. 
                                             Access to raffles, giveaways and unique 1/1 NFTs will be earned through our P2E game.   
                                            </p>
                                        </div>                                        
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className={!thirdImage ? 'timeline-img' : 'timeline-img activeBullet'} ref={refThirdimage}></div>
                        <div className="timeline-content">
                            <div className='timelineContainer'>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn={wdth>768 ? "slideInLeft" : "slideInRight"}
                                        animateOut="slideInRight"
                                        delay={400}
                                        duration={2.0}
                                        animateOnce={true}>
                                        <div className='content2 timelinContent timlineSylingRight'>
                                            <h2 style={{ color: '#008EFF', fontSize: '1.5em' }}>Launch NFT Scoring Website</h2>
                                            <p>
                                            Enjoy the perk of free access to our NFT Scoring Website. Goodfellaz owners will enjoy free access for as long as they hold. Minters will enjoy free access for life weather they hold or sell their token as a sign of appreciation for their early support.
                                             Our site is powered by cutting edge AI technology generating some of the most effective assessment &amp; ranking of upcoming NFT collections (more on this coming soon..). 
                                            </p>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn="slideInRight"
                                        animateOut="slideInLeft"
                                        duration={2.0}
                                        delay={400}
                                        animateOnce={true}>
                                        <div className='content2 timelinContent imageItem'>
                                            <span className='timelineImageRight'><img alt='no image' src="./images/nft_project_images/goodfella5.png"
                                                style={{}} /></span>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className={!fourthImage ? 'timeline-img' : 'timeline-img activeBullet'} ref={refFourthImage}></div>
                        <div className="timeline-content">
                            <div className='timelineContainer'>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn={wdth>768 ? "slideInLeft" : "slideInRight"}
                                        animateOut="slideInRight"
                                        delay={500}
                                        duration={2.0}
                                        animateOnce={true}>
                                            <div className='content2 timelinContent imageItem'>
                                            <span className='timelineImageLeft'><img alt='no image' src="./images/nft_project_images/goodfella6.png"
                                                style={{}} /></span>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn="slideInRight"
                                        animateOut="slideInLeft"
                                        delay={500}
                                        duration={2.0}
                                        animateOnce={true}>
                                            <div className='content2 timelinContent timeLineMarginLeft timlineSylingLeft'>
                                            <h2 style={{ color: '#008EFF', fontSize: '1.5em' }}> Artist Collections</h2>
                                            <p>
                                            By now you are a Goodfella earning tokens and racking up raffle prizes and you are deep into the metaverse, you need some backup! Bring in the Artist! We are partnering with professional artists to develop a series of unique NFT collections and 1/1 Artworks exclusively for the Goodfellaz Community. 
                                            </p>
                                        </div>                                        
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className={!fifthImage ? 'timeline-img' : 'timeline-img activeBullet'} ref={refFifthImage}></div>
                        <div className="timeline-content">
                            <div className='timelineContainer'>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn={wdth>768 ? "slideInLeft" : "slideInRight"}
                                        animateOut="slideInRight"
                                        delay={400}
                                        duration={2.0}
                                        animateOnce={true}>
                                        <div className='content2 timelinContent timlineSylingRight'>
                                            <h2 style={{ color: '#008EFF', fontSize: '1.5em' }}>Exclusive Experiences</h2>
                                            <p>
                                            Goodfellaz will grant access to exclusive experiences, early access to products, airdrops, physical items, exclusive invites to events including one free mint to our Wizeguy NFT Collection that is in the works and set to drop by the end of 2022. 
                                            </p>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn="slideInRight"
                                        animateOut="slideInLeft"
                                        duration={2.0}
                                        delay={400}
                                        animateOnce={true}>
                                        <div className='content2 timelinContent imageItem'>
                                            <span className='timelineImageRight'><img alt='no image' src="./images/nft_project_images/goodfella7.png"
                                                style={{}} /></span>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className={!sixthImage ? 'timeline-img' : 'timeline-img activeBullet'} ref={refSixthImage}></div>
                        <div className="timeline-content">
                            <div className='timelineContainer'>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn={wdth>768 ? "slideInLeft" : "slideInRight"}
                                        animateOut="slideInRight"
                                        delay={500}
                                        duration={2.0}
                                        animateOnce={true}>
                                            <div className='content2 timelinContent imageItem'>
                                            <span className='timelineImageLeft'><img alt='no image' src="./images/nft_project_images/goodfella3.png"
                                                style={{}} /></span>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <div className='timelineContainerItem'>
                                    <ScrollAnimation
                                        animateIn="slideInRight"
                                        animateOut="slideInLeft"
                                        delay={500}
                                        duration={2.0}
                                        animateOnce={true}>
                                            <div className='content2 timelinContent timeLineMarginLeft timlineSylingLeft'>
                                            <h2 style={{ color: '#008EFF', fontSize: '1.5em' }}>Partnerships and more <IoIosRocket /></h2>
                                            <p>
                                            Now that you are a Goodfella and a Wizeguy, get the access you deserve! You are well on your way earning prizes and tokens through our P2E game, you have 1/1 Artist designed NFTs, but, you are a Goodfella, you deserve all of the rewards! Coming up next: Tourbillon watch collection, Junior Partner, Charity Donations, Goodfellaz Merch, Staking, $Coin, and more. 
                                            </p>
                                        </div>                                        
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledTimeline>
        </ScrollAnimation >)
}

/* <section class="Testtttt">
    <div class='rwt-timeline-area rn-section-gap'>
        <div class="containerFr ">
            <div className="row">
                <div className="col-lg-12">
                        <SectionTitle
                            textAlign = "text-center"
                            radiusRounded = ""
                            subtitle = "Time Line"
                            title = "Lorem Ipsum Time"
                            description = "Lorem Ipsum."
                        />
                </div>
            </div>
            <div class="row mt_dec--20">
                <div class="col-lg-12">
                    <div class="timeline-OG">
                        <ul>
                            <li class='top-one'>
                                <div class='time'>
                                    <span><img src="./images/nft_project_images/CHRIS-BROWN-BODY.jpg" style={{width: '50%' }} /></span>
                                </div>
                                <div class="content timelinContent">
                                <h3>What Is Lorem Ipsum?</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                </p>
                                </div>
                            </li>
                            <li class='top-two'>
                                <div class='time'>
                                    <span><img src="./images/nft_project_images/CHRIS-BROWN-BODY.jpg" style={{width: '50%' }} /></span>
                                </div>
                                <div class="content timelinContent">
                                <h3>What Is Lorem Ipsum?</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                </p>
                                </div>
                            </li>
                            <li class='top-three'>
                                <div class='time'>
                                    <span><img src="./images/nft_project_images/CHRIS-BROWN-BODY.jpg" style={{width: '50%' }}/></span>
                                </div>
                                <div class="content timelinContent">
                                <h3>What Is Lorem Ipsum?</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                </div>
                            </li>
                            <li class='top-four'>
                                <div class='time'>
                                    <span><img src="./images/nft_project_images/CHRIS-BROWN-BODY.jpg" style={{width: '50%' }}/></span>
                                </div>
                                <div class="content timelinContent">
                                <h3>What Is Lorem Ipsum?</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                </p>
                                </div>
                            </li>
                        </ul>
                    </div>     
                </div>
            </div>
        </div>
    </div>
    </section> */