import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} || Home</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="The Goodfellaz have arrived! Spawned from the 90s we are ready to open a can of whoop ass in the Metaverse! Looking to own a 3D NFT but having a hard time finding one you can relate to? Look no further! Goodfellaz are unique, artist designed, and come with built in utility. Follow us along in journey!" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


